import { useCallback, useMemo, useState } from 'react'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { PATTERN } from 'configs/validation'
import Button from 'shared/components/button/Button'
import AttentionIcon from 'shared/icons/AttentionIcon'
import { LinkToCohartSupportEmail } from 'configs/contact'
import { useBoolean, useEffectOnce } from 'usehooks-ts'
import SocialAuthButtons from 'shared/components/auth/SocialAuthButtons'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'
import useAuthType from 'views/authentication/hooks/useAuthType'
import { useMediaQuery } from 'react-responsive'
import logInWithEmailAndPassword from 'views/authentication/services/logInWithEmailAndPassword'
import swapCohartToken from 'views/authentication/services/swapCohartToken'
import loginUser from 'views/authentication/services/loginUser'
import {
  authenticateWithApple,
  authenticateWithGoogle,
  authenticateWithTwitter,
  getAdditionalOAuthUserInfo,
} from 'views/authentication/services/authenticateWithProvider'
import useAuth from 'hooks/authentication/useAuth'
import { UserProfile } from 'types/V3/User'
import Router, { useRouter } from 'next/router'
import TextLabel from 'shared/components/text/TextLabel'
import StyledAuthInput from './StyledAuthInput'
import StyledPasswordInput from './StyledPasswordInput'
import { AuthErrorCodes } from 'firebase/auth'
import CohartLogo from './Logo'
import { setUpAmplitudeTrackingForSignUp } from '../util'

const errorCodesForCustomizedErrorMessage = [
  'auth/invalid-password',
  'auth/wrong-password',
  'auth/invalid-email',
  'auth/user-not-found',
]

type LoginFormInputs = {
  email: string
  password: string
}

type SignInViewProps = {
  mode: 'modal' | 'page'
  onSuccess?: () => void
}

const SignInView = (props: SignInViewProps) => {
  const { mode, onSuccess } = props
  const router = useRouter()
  const [loginError, setLoginError] = useState<string>()
  const { value: isPasswordVisible, toggle: togglePasswordVisible } = useBoolean(false)

  // authType is actually the provider type (email&password, google, apple, twitter)
  const [authType, setAuthType] = useAuthType()
  const [isRequesting, setIsRequesting] = useState(false)
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const redirectUrl = router.query.redirectUrl as string

  const { setAuthMode, showAuthModal, closeAuthModal } = useAuth()
  const showSignUpModal = useCallback(() => {
    setAuthMode('signup')
    showAuthModal()
  }, [setAuthMode, showAuthModal])

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>()

  const email = watch('email')
  const password = watch('password')
  const enableSubmitButton = Boolean(email) && Boolean(password)

  useEffectOnce(() => {
    trackAmplitudeCustomEvent('user_sign_in_started')
  })

  const handleAuthenticateError = useCallback((e: unknown) => {
    let errorMessage = 'Something went wrong. Please try again later.'
    if (e && typeof e === 'object' && 'code' in e && 'message' in e) {
      if (errorCodesForCustomizedErrorMessage.includes(e.code as string)) {
        errorMessage = 'Invalid email or password. Please check your credentials and try again.' // customized firebase error
      } else if (e.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
        errorMessage = 'User closed the authentication popup'
      } else errorMessage = e.message as string // Firebase error
    } else if (typeof e === 'string') {
      errorMessage = e
    }
    setLoginError(errorMessage)
  }, [])

  const handleAuthenticateSuccess = async (user: UserProfile) => {
    if (onSuccess) {
      return onSuccess()
    }

    if (mode === 'modal') {
      closeAuthModal()
      return // do not redirect if sign-in is in modal
    }
    // redirect to the correct page based on user role if
    const defaultRedirectTo = (() => {
      if (redirectUrl) return redirectUrl
      const { role } = user
      if (role === 'creator') return '/artist/storefront'
      if (role === 'independent-advisor' || role === 'cohart-advisor') return '/advisor/invoices'
      if (role === 'collector') return `/collector/overview`
      return '/homepage' // homepage dashboard
    })()

    await Router.push(defaultRedirectTo)
  }

  const submitForm = async (data: LoginFormInputs) => {
    setLoginError(undefined)
    setIsRequesting(true)
    setAuthType('password')
    try {
      const userCredential = await logInWithEmailAndPassword(data.email, data.password)

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential)

      // mark user as logged in
      await loginUser(user)
      // execute success callback
      await handleAuthenticateSuccess(user)
      trackAmplitudeCustomEvent('user_signed_in', { email: data.email, type: 'email' })
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signInWithGoogle = async () => {
    setAuthType('google')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithGoogle(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback
      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential)
      const additionalUserInfo = getAdditionalOAuthUserInfo(userCredential)

      // mark user as logged in
      await loginUser(user)
      // execute success callback
      await handleAuthenticateSuccess(user)

      // track user sign-in / sign-up event
      if (additionalUserInfo?.isNewUser) {
        setUpAmplitudeTrackingForSignUp({
          userId: user.id,
          signUpMethod: 'google',
          asRole: 'henry',
          email: user.email,
          firstName: user.firstName,
          createdAt: user.createdAt,
        })
      } else trackAmplitudeCustomEvent('user_signed_in', { type: 'google' })
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signInWithApple = async () => {
    setAuthType('apple')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithApple(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback
      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential)
      const additionalUserInfo = getAdditionalOAuthUserInfo(userCredential)

      // mark user as logged in
      await loginUser(user)
      // execute success callback
      await handleAuthenticateSuccess(user)

      // track user sign-in / sign-up event
      if (additionalUserInfo?.isNewUser) {
        setUpAmplitudeTrackingForSignUp({
          userId: user.id,
          signUpMethod: 'apple',
          asRole: 'henry',
          email: user.email,
          firstName: user.firstName,
          createdAt: user.createdAt,
        })
      } else trackAmplitudeCustomEvent('user_signed_in', { type: 'apple' })
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signInWithTwitter = async () => {
    setAuthType('twitter')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithTwitter(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback
      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential)
      // mark user as logged in
      await loginUser(user)
      // execute success callback
      await handleAuthenticateSuccess(user)
      trackAmplitudeCustomEvent('user_signed_in', { type: 'twitter' })
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const renderMessage = useMemo(() => {
    if (loginError)
      return (
        <div className="mt-6 flex items-center gap-1 text-xs normal-case">
          <div className="w-3">
            <AttentionIcon color="#FF4337" className="!size-3" />
          </div>
          <span className="text-[#FF4337]">&nbsp;{loginError}</span>
        </div>
      )

    return (
      <div>
        <div className="mt-2 flex items-center text-[10px] normal-case">
          <AttentionIcon color="#0F6BFF" />
          <span className="text-[#0F6BFF]">
            &nbsp;Are you already in the app?
            <span className="hidden xl:inline">&nbsp;Click 'Forget Password' below to set it up</span>
          </span>
        </div>
        <span className="ml-[14px] block text-[10px] normal-case text-[#0F6BFF] xl:hidden">
          Click <span className="font-semibold">'Forget Password'</span> below to set it up
        </span>
      </div>
    )
  }, [loginError])

  return (
    <div className="box-border h-full w-[80vw] space-y-3 font-inter md:w-[450px] lg:space-y-4 xl:space-y-6 2xl:space-y-8">
      <h2 className="text-center font-monument-grotes text-3xl font-medium leading-none text-[#191414] lg:text-[48px]">
        Welcome back
      </h2>

      {/* social buttons */}
      <SocialAuthButtons
        onGoogleButtonClick={signInWithGoogle}
        onTwitterButtonClick={signInWithTwitter}
        onAppleButtonClick={signInWithApple}
        isGoogleButtonLoading={isRequesting && authType === 'google'}
        isTwitterButtonLoading={isRequesting && authType === 'twitter'}
        isAppleButtonLoading={isRequesting && authType === 'apple'}
      />

      <div className="flex w-full items-center opacity-50">
        <div className="h-[0.5px] grow bg-[#191414]" />
        <div className="mx-5 text-[12px] font-semibold uppercase text-[#191414]">Or sign in with</div>
        <div className="h-[0.5px] grow bg-[#191414]" />
      </div>

      {/* form content */}
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="w-full space-y-3">
          <TextLabel label={'Email Address'} asterisk={true} size={'default'} as="label">
            <div className="h-2" />
            <StyledAuthInput
              placeholder="Enter email address"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: PATTERN.email,
                  message: 'Please enter a valid email',
                },
              })}
            />
            {errors.email?.message && (
              <p className="mt-1 text-xs normal-case text-[#FF4337] ">{errors.email?.message}</p>
            )}{' '}
          </TextLabel>
          <TextLabel label={'Password'} asterisk={true} size={'default'} as="label">
            <div className="h-2" />
            <StyledPasswordInput
              isPasswordVisible={isPasswordVisible}
              togglePasswordVisible={togglePasswordVisible}
              placeholder="Enter password"
              {...register('password', { required: { value: true, message: 'Password is required' } })}
            />
            {errors.password?.message && (
              <p className="mt-1 text-xs normal-case text-[#FF4337]">{errors.password?.message}</p>
            )}{' '}
          </TextLabel>
        </div>
        {renderMessage}
        {/* submit button */}
        <Button
          type="submit"
          name="Sign In"
          wrapperClass="mt-6 w-full rounded-[32px] !p-3 lg:!p-4 border border-black/10 bg-primary"
          contentClass="!text-sm lg:!text-base !font-semibold text-white"
          loadingClassName="!border-b-white !border-r-white !border-t-white"
          disabled={(isRequesting && authType === 'password') || !enableSubmitButton}
          loading={isRequesting && authType === 'password'}
        />
      </form>

      <div className="space-y-2 lg:space-y-3">
        {/* forgot password */}
        <div className="gap-4 text-center text-sm font-semibold text-primary">
          <Link passHref href="/forgot-password" className="hover:underline">
            Forgot Password?
          </Link>
        </div>

        {/* sign up link */}
        <div className="flex w-full justify-center text-sm ">
          <span>
            Not yet on Cohart?{' '}
            {mode === 'page' && (
              <Link
                passHref
                href={redirectUrl ? `/sign-up?redirectUrl=${encodeURIComponent(redirectUrl)}` : '/sign-up'}
                className="font-semibold text-primary hover:underline"
              >
                Sign up
              </Link>
            )}
            {mode === 'modal' && (
              <a className="font-semibold text-primary hover:underline" role="button" onClick={showSignUpModal}>
                Sign up
              </a>
            )}
          </span>
        </div>

        {/* support link */}
        <div className="w-full text-center text-xs text-[#898788]">
          Need help?&nbsp;
          <a className="font-semibold text-primary  hover:underline" href={LinkToCohartSupportEmail}>
            Contact Support
          </a>
        </div>
      </div>

      {/* logo */}
      <CohartLogo />
    </div>
  )
}

export default SignInView
